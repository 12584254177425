import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-586120ae"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "page-layout"
};
var _hoisted_2 = {
  "class": "table-container"
};
var _hoisted_3 = {
  "class": "pagination-panel"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_table_column = _resolveComponent("el-table-column");
  var _component_el_table = _resolveComponent("el-table");
  var _component_el_pagination = _resolveComponent("el-pagination");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_table, {
    data: $setup.firmwareList,
    stripe: "",
    fit: "",
    height: "100%"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_el_table_column, {
        align: "center",
        label: "序号",
        width: "75"
      }, {
        "default": _withCtx(function (scope) {
          return [_createTextVNode(_toDisplayString(scope.$index + 1), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_table_column, {
        align: "center",
        label: "固件名称",
        width: "100",
        prop: "firmwareName"
      }), _createVNode(_component_el_table_column, {
        align: "center",
        label: "版本",
        prop: "firmwareVersion"
      }), _createVNode(_component_el_table_column, {
        align: "center",
        label: "大小"
      }, {
        "default": _withCtx(function (scope) {
          var _a;
          return [_createTextVNode(_toDisplayString((_a = scope.row.fileSize) === null || _a === void 0 ? void 0 : _a.asByteSizeFormat(2)), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_table_column, {
        align: "center",
        label: "发布时间"
      }, {
        "default": _withCtx(function (scope) {
          return [_createTextVNode(_toDisplayString($setup.JlinkUtils.date.parseTime(scope.row.releaseDate)), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_table_column, {
        align: "right"
      }, {
        header: _withCtx(function () {
          return [$setup.canControl ? (_openBlock(), _createBlock($setup["DeepButton"], {
            key: 0,
            "class": "button",
            onClick: $setup.handleFirmwareFlush,
            size: "small"
          }, {
            "default": _withCtx(function () {
              return [_createTextVNode("刷新固件库")];
            }),
            _: 1 /* STABLE */
          })) : _createCommentVNode("v-if", true)];
        }),
        _: 1 /* STABLE */
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["data"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_pagination, {
    currentPage: $setup.remoteEmp.page,
    "onUpdate:currentPage": _cache[0] || (_cache[0] = function ($event) {
      return $setup.remoteEmp.page = $event;
    }),
    "page-size": $setup.remoteEmp.size,
    layout: "prev, pager, next, jumper",
    total: $setup.totalCount
  }, null, 8 /* PROPS */, ["currentPage", "page-size", "total"])])]);
}