import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-b4806830"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "docks"
};
var _hoisted_2 = {
  style: {
    "width": "100%",
    "display": "flex",
    "justify-content": "center"
  }
};
var _hoisted_3 = {
  "class": "online"
};
var _hoisted_4 = {
  "class": "pagination-panel"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_table_column = _resolveComponent("el-table-column");
  var _component_el_tag = _resolveComponent("el-tag");
  var _component_el_table = _resolveComponent("el-table");
  var _component_el_pagination = _resolveComponent("el-pagination");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_table, {
    data: $setup.droneList,
    stripe: "",
    fit: "",
    height: "100%",
    size: "large",
    "table-layout": "auto"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_el_table_column, {
        align: "center",
        label: "序号",
        width: "75"
      }, {
        "default": _withCtx(function (scope) {
          return [_createTextVNode(_toDisplayString(scope.$index + 1), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_table_column, {
        align: "center",
        label: "图片",
        widyh: "80"
      }, {
        "default": _withCtx(function (scope) {
          return [_createElementVNode("div", _hoisted_2, [(_openBlock(), _createBlock($setup["DeepDockIcon"], {
            key: scope.row.droneId,
            "class": "image",
            fit: "cover",
            src: scope.row,
            lazy: ""
          }, null, 8 /* PROPS */, ["src"]))])];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_table_column, {
        align: "center",
        prop: "droneName",
        label: "机场名称",
        widyh: "85"
      }), _createVNode(_component_el_table_column, {
        align: "center",
        prop: "gatewayName",
        label: "机场型号"
      }), _createVNode(_component_el_table_column, {
        align: "center",
        label: "机场序列号",
        prop: "gatewaySerialNumber"
      }), _createVNode(_component_el_table_column, {
        align: "center",
        label: "机场固件版本"
      }, {
        "default": _withCtx(function (scope) {
          return [_createElementVNode("span", null, _toDisplayString(scope.row.gatewayFirmwareVersion), 1 /* TEXT */), scope.row.gatewayFirmwareVersion && $setup.JlinkType.user.isSuper ? (_openBlock(), _createBlock(_component_el_tag, {
            key: 0,
            style: {
              "margin-left": ".5rem"
            },
            onClick: function onClick($event) {
              return $setup.handleGatewayFirmwareAction(scope.row);
            },
            type: scope.row.gatewayFirmwareBehind ? 'warning' : 'success'
          }, {
            "default": _withCtx(function () {
              return [_createTextVNode("更新固件 ")];
            }),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick", "type"])) : _createCommentVNode("v-if", true)];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_table_column, {
        align: "center",
        prop: "droneName",
        label: "飞行器"
      }, {
        "default": _withCtx(function (scope) {
          return [_createElementVNode("span", null, _toDisplayString(scope.row.aircraftName || "------"), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_table_column, {
        align: "center",
        label: "飞行器序列号"
      }, {
        "default": _withCtx(function (scope) {
          return [_createElementVNode("span", null, _toDisplayString(scope.row.aircraftSerialNumber || "------"), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_table_column, {
        label: "飞机固件版本"
      }, {
        "default": _withCtx(function (scope) {
          return [_createElementVNode("span", null, _toDisplayString(scope.row.aircraftFirmwareVersion), 1 /* TEXT */), _createCommentVNode("\n                      <el-tag style=\"margin-left: .5rem\" v-if=\"scope.row.aircraftFirmwareVersion\" @click=\"handleAircraftFirmwareAction(scope.row)\" :type=\"scope.row.aircraftFirmwareBehind?'warning':'success'\">{{scope.row.aircraftFirmwareBehind?'更新':'检查'}}</el-tag>\n          ")];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_table_column, {
        prop: "assignmentsName",
        label: "所属任务"
      }), _createVNode(_component_el_table_column, {
        label: "online"
      }, {
        "default": _withCtx(function (scope) {
          return [_createElementVNode("div", _hoisted_3, [_createVNode($setup["DroneOnlineVernier"], {
            drone: scope.row,
            gateway: ""
          }, null, 8 /* PROPS */, ["drone"]), _createVNode($setup["DroneOnlineVernier"], {
            drone: scope.row
          }, null, 8 /* PROPS */, ["drone"])])];
        }),
        _: 1 /* STABLE */
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["data"]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_el_pagination, {
    currentPage: $setup.remoteEmp.page,
    "onUpdate:currentPage": _cache[0] || (_cache[0] = function ($event) {
      return $setup.remoteEmp.page = $event;
    }),
    "page-size": $setup.remoteEmp.size,
    layout: "prev, pager, next, jumper",
    total: $setup.totalCount
  }, null, 8 /* PROPS */, ["currentPage", "page-size", "total"])])]);
}