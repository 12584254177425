import "core-js/modules/es.function.name.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-25dda031"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "group"
};
var _hoisted_2 = {
  "class": "left"
};
var _hoisted_3 = {
  "class": "container"
};
var _hoisted_4 = ["onClick"];
var _hoisted_5 = {
  "class": "bottom"
};
var _hoisted_6 = {
  "class": "right"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_tooltip = _resolveComponent("el-tooltip");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.groupValues, function (item) {
    return _openBlock(), _createElementBlock("div", {
      "class": _normalizeClass(["action", $setup.activeName === item.key ? 'icon-selected' : '']),
      key: item.key,
      onClick: function onClick($event) {
        return $setup.handleClick(item.key);
      }
    }, [_createVNode(_component_el_tooltip, {
      placement: "right",
      content: item.name
    }, {
      "default": _withCtx(function () {
        return [_createVNode($setup["DeepSvgIcon"], {
          name: item.icon,
          "class": "icon"
        }, null, 8 /* PROPS */, ["name"])];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["content"])], 10 /* CLASS, PROPS */, _hoisted_4);
  }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_el_tooltip, {
    placement: "right",
    content: $setup.version
  }, {
    "default": _withCtx(function () {
      return [_createVNode($setup["DeepSvgIcon"], {
        name: "version",
        "class": "icon"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["content"])])]), _createElementVNode("div", _hoisted_6, [_createVNode(_Transition, {
    name: "slide-fade-transform",
    mode: "out-in"
  }, {
    "default": _withCtx(function () {
      return [(_openBlock(), _createBlock(_resolveDynamicComponent($setup.currentComponent), {
        key: $setup.currentComponent
      }))];
    }),
    _: 1 /* STABLE */
  })])]);
}