import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-8fd68d08"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "page-layout"
};
var _hoisted_2 = {
  "class": "header-panel"
};
var _hoisted_3 = {
  "class": "container-panel"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "header", {}, undefined, true)]), _createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "content", {}, undefined, true)])]);
}