import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_tab_pane = _resolveComponent("el-tab-pane");
  var _component_el_tabs = _resolveComponent("el-tabs");
  return _openBlock(), _createBlock(_component_el_tabs, {
    type: "border-card",
    modelValue: $setup.editableTabsValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $setup.editableTabsValue = $event;
    })
  }, {
    "default": _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.editableTabs, function (item, index) {
        return _openBlock(), _createBlock(_component_el_tab_pane, {
          lazy: "",
          key: index,
          label: item.title,
          name: index
        }, {
          "default": _withCtx(function () {
            return [(_openBlock(), _createBlock(_resolveDynamicComponent(item.content)))];
          }),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "name"]);
      }), 128 /* KEYED_FRAGMENT */))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]);
}