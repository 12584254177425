import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-cddbc03e"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "members"
};
var _hoisted_2 = {
  style: {
    "width": "100%",
    "display": "flex",
    "justify-content": "center"
  }
};
var _hoisted_3 = {
  style: {
    "display": "inline-flex",
    "justify-content": "center",
    "align-items": "center"
  }
};
var _hoisted_4 = {
  key: 0,
  "class": "icon-icon"
};
var _hoisted_5 = {
  "class": "pagination-panel"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_table_column = _resolveComponent("el-table-column");
  var _component_el_tooltip = _resolveComponent("el-tooltip");
  var _component_el_table = _resolveComponent("el-table");
  var _component_el_pagination = _resolveComponent("el-pagination");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_table, {
    data: $setup.membersList,
    stripe: "",
    fit: "",
    height: "100%"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_el_table_column, {
        align: "center",
        label: "序号",
        width: "75"
      }, {
        "default": _withCtx(function (scope) {
          return [_createTextVNode(_toDisplayString(scope.$index + 1), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_table_column, {
        align: "center",
        label: "头像"
      }, {
        "default": _withCtx(function (scope) {
          return [_createElementVNode("div", _hoisted_2, [(_openBlock(), _createBlock($setup["DeepUserIcon"], {
            key: scope.row.userId,
            "class": "image",
            fit: "cover",
            src: scope.row,
            lazy: ""
          }, null, 8 /* PROPS */, ["src"]))])];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_table_column, {
        align: "center",
        prop: "userName",
        label: "姓名"
      }), _createVNode(_component_el_table_column, {
        align: "center",
        prop: "userMobile",
        label: "手机号"
      }), _createVNode(_component_el_table_column, {
        align: "center",
        label: "飞行执照类型",
        width: "110"
      }, {
        "default": _withCtx(function (scope) {
          return [_createTextVNode(_toDisplayString(scope.row.userLicense ? scope.row.userLicense.licenseName : "无"), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_table_column, {
        align: "center",
        label: "飞行执照号"
      }, {
        "default": _withCtx(function (scope) {
          return [_createTextVNode(_toDisplayString(scope.row.userLicense ? scope.row.userLicense.licenseNo : "无"), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_table_column, {
        prop: "role",
        label: "组织"
      }, {
        "default": _withCtx(function (scope) {
          return [_createTextVNode(_toDisplayString(scope.row.companyName), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_table_column, {
        prop: "role",
        label: "用户权限"
      }, {
        "default": _withCtx(function (scope) {
          return [_createTextVNode(_toDisplayString($setup.JlinkTransform.user.toRole(scope.row.userAuthorities)), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_table_column, {
        label: "状态"
      }, {
        "default": _withCtx(function (scope) {
          return [_createTextVNode(_toDisplayString($setup.JlinkTransform.user.toStatus(scope.row.userStatus)), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }), $setup.canControl ? (_openBlock(), _createBlock(_component_el_table_column, {
        key: 0,
        label: "操作"
      }, {
        "default": _withCtx(function (scope) {
          return [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_tooltip, {
            placement: "top",
            content: "编辑"
          }, {
            "default": _withCtx(function () {
              return [_createVNode($setup["DeepSvgIcon"], {
                name: "edit_group",
                "class": "icon-icon",
                onClick: function onClick($event) {
                  return $setup.handleClick(scope.row);
                }
              }, null, 8 /* PROPS */, ["onClick"])];
            }),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */), _createVNode(_component_el_tooltip, {
            placement: "top",
            content: "删除"
          }, {
            "default": _withCtx(function () {
              return [$setup.JlinkType.user.isSelf(scope.row.userId) ? (_openBlock(), _createElementBlock("div", _hoisted_4)) : (_openBlock(), _createBlock($setup["DeepSvgIcon"], {
                key: 1,
                name: "garbage",
                "class": "icon-icon",
                onClick: function onClick($event) {
                  return $setup.handleDelete(scope.row);
                }
              }, null, 8 /* PROPS */, ["onClick"]))];
            }),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)])];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["data"]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_el_pagination, {
    currentPage: $setup.remoteEmp.page,
    "onUpdate:currentPage": _cache[0] || (_cache[0] = function ($event) {
      return $setup.remoteEmp.page = $event;
    }),
    "page-size": $setup.remoteEmp.size,
    layout: "prev, pager, next, jumper",
    total: $setup.totalCount
  }, null, 8 /* PROPS */, ["currentPage", "page-size", "total"])])]);
}