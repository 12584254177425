import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-44c17290"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "devices"
};
var _hoisted_2 = {
  style: {
    "width": "100%",
    "display": "flex",
    "justify-content": "center"
  }
};
var _hoisted_3 = {
  "class": "pagination-panel"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_table_column = _resolveComponent("el-table-column");
  var _component_el_table = _resolveComponent("el-table");
  var _component_el_pagination = _resolveComponent("el-pagination");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_table, {
    data: $setup.deviceList,
    stripe: "",
    fit: "",
    height: "100%",
    size: "large",
    "table-layout": "auto"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_el_table_column, {
        align: "center",
        label: "序号",
        width: "75"
      }, {
        "default": _withCtx(function (scope) {
          return [_createTextVNode(_toDisplayString(scope.$index + 1), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_table_column, {
        align: "center",
        label: "图片",
        widyh: "80"
      }, {
        "default": _withCtx(function (scope) {
          return [_createElementVNode("div", _hoisted_2, [(_openBlock(), _createBlock($setup["DeepDronePartIcon"], {
            key: scope.row.droneId,
            "class": "image",
            fit: "cover",
            src: scope.row,
            lazy: ""
          }, null, 8 /* PROPS */, ["src"]))])];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_table_column, {
        align: "center",
        prop: "dronePartsNickName",
        label: "配件名称",
        widyh: "85"
      }), _createVNode(_component_el_table_column, {
        align: "center",
        prop: "dronePartsName",
        label: "配件型号"
      }), _createVNode(_component_el_table_column, {
        align: "center",
        prop: "dronePartsSerialNumber",
        label: "配件序列号"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["data"]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_pagination, {
    currentPage: $setup.remoteEmp.page,
    "onUpdate:currentPage": _cache[0] || (_cache[0] = function ($event) {
      return $setup.remoteEmp.page = $event;
    }),
    "page-size": $setup.remoteEmp.size,
    layout: "prev, pager, next, jumper",
    total: $setup.totalCount
  }, null, 8 /* PROPS */, ["currentPage", "page-size", "total"])])]);
}