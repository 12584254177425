import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-57596484"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "page-layout"
};
var _hoisted_2 = {
  "class": "table-container"
};
var _hoisted_3 = {
  style: {
    "display": "inline-flex",
    "justify-content": "center",
    "align-items": "center"
  }
};
var _hoisted_4 = {
  style: {
    "display": "inline-flex",
    "margin-right": "1rem"
  }
};
var _hoisted_5 = {
  "class": "pagination-panel"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_table_column = _resolveComponent("el-table-column");
  var _component_el_tag = _resolveComponent("el-tag");
  var _component_el_tooltip = _resolveComponent("el-tooltip");
  var _component_el_table = _resolveComponent("el-table");
  var _component_el_pagination = _resolveComponent("el-pagination");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_table, {
    data: $setup.businessPackageList,
    stripe: "",
    fit: "",
    height: "100%"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_el_table_column, {
        align: "center",
        label: "序号",
        width: "75"
      }, {
        "default": _withCtx(function (scope) {
          return [_createTextVNode(_toDisplayString(scope.$index + 1), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_table_column, {
        align: "center",
        prop: "businessName",
        label: "版本名称"
      }), _createVNode(_component_el_table_column, {
        align: "center",
        label: "设备限制"
      }, {
        "default": _withCtx(function (scope) {
          return [_createVNode(_component_el_tag, null, {
            "default": _withCtx(function () {
              return [_createTextVNode(_toDisplayString(scope.row.deviceNumLimit) + "台 ", 1 /* TEXT */)];
            }),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_table_column, {
        align: "center",
        label: "成员限制"
      }, {
        "default": _withCtx(function (scope) {
          return [_createVNode(_component_el_tag, null, {
            "default": _withCtx(function () {
              return [_createTextVNode(_toDisplayString(scope.row.memberNumLimit) + "人 ", 1 /* TEXT */)];
            }),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_table_column, {
        align: "center",
        label: "任务限制"
      }, {
        "default": _withCtx(function (scope) {
          return [_createVNode(_component_el_tag, null, {
            "default": _withCtx(function () {
              return [_createTextVNode(_toDisplayString(scope.row.assignmentsNumLimit) + "个 ", 1 /* TEXT */)];
            }),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_table_column, {
        align: "center",
        label: "网盘容量"
      }, {
        "default": _withCtx(function (scope) {
          return [_createVNode(_component_el_tag, null, {
            "default": _withCtx(function () {
              return [_createTextVNode(_toDisplayString((scope.row.diskVolumeLimit / 1024.0).toFixedCustom(1)) + "GB ", 1 /* TEXT */)];
            }),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_table_column, {
        align: "center",
        label: "直播时长"
      }, {
        "default": _withCtx(function (scope) {
          return [_createVNode(_component_el_tag, null, {
            "default": _withCtx(function () {
              return [_createTextVNode(_toDisplayString(scope.row.liveNumLimit), 1 /* TEXT */)];
            }),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_table_column, {
        align: "center",
        label: "定制logo",
        prop: "logoCustom"
      }), _createVNode(_component_el_table_column, {
        align: "center",
        label: "自定义",
        prop: "custom"
      }), $setup.canControl ? (_openBlock(), _createBlock(_component_el_table_column, {
        key: 0,
        align: "center",
        label: "操作"
      }, {
        "default": _withCtx(function (scope) {
          return [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_tooltip, {
            placement: "top",
            content: "编辑"
          }, {
            "default": _withCtx(function () {
              return [_createVNode($setup["DeepSvgIcon"], {
                name: "edit_group",
                "class": _normalizeClass(scope.row.custom ? 'icon-icon' : 'icon-icon-no'),
                onClick: function onClick($event) {
                  return $setup.handleClick(scope.row);
                }
              }, null, 8 /* PROPS */, ["class", "onClick"])];
            }),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */), _createVNode(_component_el_tooltip, {
            placement: "top",
            content: "删除"
          }, {
            "default": _withCtx(function () {
              return [_createVNode($setup["DeepSvgIcon"], {
                name: "garbage",
                "class": _normalizeClass(scope.row.custom ? 'icon-icon' : 'icon-icon-no'),
                onClick: function onClick($event) {
                  return $setup.handleDelete(scope.row);
                }
              }, null, 8 /* PROPS */, ["class", "onClick"])];
            }),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)])];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createVNode(_component_el_table_column, {
        align: "right",
        width: "240"
      }, {
        header: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_4, [$setup.canControl ? (_openBlock(), _createBlock($setup["DeepButton"], {
            key: 0,
            "class": "button",
            onClick: $setup.handleAdd,
            size: "small"
          }, {
            "default": _withCtx(function () {
              return [_createTextVNode("添加版本")];
            }),
            _: 1 /* STABLE */
          })) : _createCommentVNode("v-if", true), _createVNode($setup["DeepButton"], {
            "class": "button",
            onClick: $setup.refresh,
            size: "small"
          }, {
            "default": _withCtx(function () {
              return [_createTextVNode("刷新版本")];
            }),
            _: 1 /* STABLE */
          })])];
        }),
        _: 1 /* STABLE */
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["data"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_el_pagination, {
    currentPage: $setup.remoteEmp.page,
    "onUpdate:currentPage": _cache[0] || (_cache[0] = function ($event) {
      return $setup.remoteEmp.page = $event;
    }),
    "page-size": $setup.remoteEmp.size,
    layout: "prev, pager, next, jumper",
    total: $setup.totalCount
  }, null, 8 /* PROPS */, ["currentPage", "page-size", "total"])])]);
}