import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-dee93fe8"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "left"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = {
  "class": "right"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createBlock($setup["PageLayout"], {
    "class": "page-search"
  }, {
    header: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.pageDataList, function (item) {
        return _openBlock(), _createElementBlock("p", {
          "class": _normalizeClass($setup.pageData === item.text ? 'select' : ''),
          onClick: function onClick($event) {
            return $setup.handleSearch(item.text);
          },
          key: item.id
        }, _toDisplayString(item.title), 11 /* TEXT, CLASS, PROPS */, _hoisted_2);
      }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("div", _hoisted_3, [_createVNode($setup["CompanySelector"], {
        modelValue: $setup.companyId,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $setup.companyId = $event;
        })
      }, null, 8 /* PROPS */, ["modelValue"])])];
    }),
    content: _withCtx(function () {
      return [$setup.pageData === 'dock' ? (_openBlock(), _createBlock($setup["Docks"], {
        key: 0,
        companyId: $setup.companyId
      }, null, 8 /* PROPS */, ["companyId"])) : $setup.pageData === 'dronePart' ? (_openBlock(), _createBlock($setup["DroneParts"], {
        key: 1,
        companyId: $setup.companyId
      }, null, 8 /* PROPS */, ["companyId"])) : $setup.pageData === 'member' ? (_openBlock(), _createBlock($setup["Members"], {
        key: 2,
        companyId: $setup.companyId
      }, null, 8 /* PROPS */, ["companyId"])) : (_openBlock(), _createBlock($setup["Drones"], {
        key: 3,
        companyId: $setup.companyId
      }, null, 8 /* PROPS */, ["companyId"]))];
    }),
    _: 1 /* STABLE */
  });
}